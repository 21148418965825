import { Injectable } from '@angular/core';
import { Platform } from '@ionic/angular';
import { CapacitorSQLite, SQLiteConnection } from '@capacitor-community/sqlite';
import { DataSource } from 'typeorm';
import { Device } from '../../entities/device';
import { ToastUtil } from '../../util/toast-util';
import { Example } from '../../entities/example';
import { Localization } from '../../entities/localization';
import { DATABASE, DATABASE_NAME, DATABASE_TYPE } from '../../constants/database-config.constants';
import { Measurement } from '../../entities/measurement';
import { Probe } from '../../entities/probe';
import { Parameter } from '../../entities/parameter';

@Injectable({
  providedIn: 'root'
})
export class DatabaseService {
  private _appDataSource: DataSource;

  constructor(
    private _platform: Platform,
    private _toastUtil: ToastUtil,
  ) { }

  async initializeConnection(): Promise<void> {
    this._platform.ready().then(async () => {
      CapacitorSQLite.checkConnectionsConsistency({
        dbNames: [],
      }).catch((e) => ({}));

      const sqliteConnection = new SQLiteConnection(CapacitorSQLite);

      this._appDataSource = new DataSource({
        name: DATABASE_NAME,
        type: DATABASE_TYPE,
        driver: sqliteConnection,
        database: DATABASE,
        logging: [
          'error',
          'query',
          'schema',
        ],
        // Tu trzeba dodawać wszystkie encje, które będą wykorzystywane przez aplikację
        entities: [
          Device,
          Example,
          Localization,
          Measurement,
          Probe,
          Parameter,
        ],
        synchronize: true,
        mode: 'no-encryption',
        dropSchema: false, // Jak true to usuwa bazę, przy każdym uruchomieniu aplikacji
      });

      this._appDataSource.initialize().then( () =>
        this._toastUtil.presentSuccessToast(`Data source has been initialized`)
      ).catch((e) => {
        this._toastUtil.presentErrorToast(e);
      });
    });
  }

  get appDataSource(): DataSource {
    return this._appDataSource;
  }
}
