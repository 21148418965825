import { Routes } from '@angular/router';

export const NOT_AUTH_ROUTES: Routes = [
  {
    path: 'home',
    loadChildren: () => import('../../pages/home/home.module').then(m => m.HomePageModule)
  },
  {
    path: 'about',
    loadChildren: () => import('../../pages/about/about.module').then( m => m.AboutPageModule)
  },
  {
    path: 'settings',
    loadChildren: () => import('../../pages/settings/settings.module').then( m => m.SettingsPageModule)
  },
  {
    path: 'connections',
    loadChildren: () => import('../../pages/connections/connections.module').then( m => m.ConnectionsPageModule)
  },
  {
    path: 'remembered-devices',
    loadChildren: () => import('../../pages/remembered-devices/remembered-devices.module').then(m => m.RememberedDevicesPageModule)
  },
  {
    path: 'measurements',
    loadChildren: () => import('../../pages/measurement/measurement.module').then(m => m.MeasurementPageModule)
  },
  {
    path: 'measurement-chart',
    loadChildren: () => import('../../pages/measurement-chart/measurement-chart.module').then(m => m.MeasurementChartPageModule)
  },
  {
    path: 'measurement-mesh',
    loadChildren: () => import('../../pages/measurement-mesh/measurement-mesh.module').then(m => m.MeasurementMeshPageModule)
  },
];
