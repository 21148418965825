enum ESuccess {
  header = 'action.success',
  color = 'success',
  icon = 'checkmark',
}

enum EWarning {
  header = 'action.warning',
  color = 'warning',
  icon = 'warning',
}

enum EError {
  header = 'action.error',
  color = 'danger',
  icon = 'bug',
}

export class ToastEnum {
  static readonly success = ESuccess;
  static readonly warning = EWarning;
  static readonly error = EError;
}
