import { Injectable } from '@angular/core';
import { Credentials } from '../models';
import { NgxRolesService } from 'ngx-permissions';

const credentialsKey = 'credentials-mur-mobile-app';

/**
 * Provides storage for authentication credentials.
 * The Credentials interfaces should be replaced with proper implementation.
 */
@Injectable({
  providedIn: 'root',
})
export class CredentialsService {
  defaultTokenType = 'Bearer';
  private _credentials: Credentials | null = null;

  constructor(
    private _ngxRoleService: NgxRolesService
  ) {
    const savedCredentials = sessionStorage.getItem(credentialsKey) || localStorage.getItem(credentialsKey);

    if (savedCredentials) {
      this._credentials = JSON.parse(savedCredentials);
      this._credentials.user.roles.forEach(role => {
        this._ngxRoleService.addRoleWithPermissions(role.name, role.permissions);
      });
    }
  }

  /**
   * Checks is the user is authenticated.
   *
   * @return True if the user is authenticated.
   */
  isAuthenticated(): boolean {
    return !!this.credentials;
  }

  /**
   *
   */
  getToken(): string | null {
    if (this._credentials != null) {
      return this._credentials.token;
    }
    return null;
  }

  /**
   * Get refresh token
   */
  getRefreshToken(): string | null {
    if (this._credentials != null) {
      return this._credentials.refreshToken;
    }

    return null;
  }

  /**
   * Get auth header with token
   */
  getAuthHeader(): string | null {
    if (this._credentials != null) {
      return `${this.defaultTokenType} ${this._credentials.token}`;
    }
    return null;
  }

  /**
   * Gets the user credentials.
   *
   * @return The user credentials or null if the user is not authenticated.
   */
  get credentials(): Credentials | null {
    return this._credentials;
  }

  /**
   * Sets the user credentials.
   * The credentials may be persisted across sessions by setting the `remember` parameter to true.
   * Otherwise, the credentials are only persisted for the current session.
   *
   * @param credentials The user credentials.
   * @param remember True to remember credentials across sessions.
   */
  setCredentials(credentials?: Credentials, remember?: boolean): void {
    this._credentials = credentials || null;

    if (credentials) {
      const storage = remember ? localStorage : sessionStorage;
      storage.setItem(credentialsKey, JSON.stringify(credentials));
    } else {
      sessionStorage.removeItem(credentialsKey);
      localStorage.removeItem(credentialsKey);
    }
  }
}
