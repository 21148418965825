import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivate,
  CanActivateChild,
  Router,
  RouterStateSnapshot,
  UrlTree
} from '@angular/router';
import { Observable } from 'rxjs';
import { CredentialsService } from '../services';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate, CanActivateChild {

  constructor(
    private _router: Router,
    private _credentialsService: CredentialsService
  ) {
  }

  /**
   * Can active
   *
   * @param route
   * @param state
   */
  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): boolean {
    return this.check(state.url);
  }

  /**
   * Can activate child
   *
   * @param childRoute
   * @param state
   */
  canActivateChild(
    childRoute: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    return this.check(state.url);
  }

  /**
   * Check the authenticated status
   *
   * @param redirectURL
   * @private
   */
  private check(redirectURL: string): boolean {
    if (this._credentialsService.isAuthenticated()) {
      return true;
    }

    this._router.navigate(['auth/login'], {
      queryParams: { redirect: redirectURL },
      replaceUrl: true,
    });

    return false;
  }
}
