import { Column, Entity, PrimaryGeneratedColumn } from 'typeorm';
import { ESyncStatus } from '../enums/sync-status.enum';

@Entity('measurement')
export class Measurement {

  @PrimaryGeneratedColumn()
  id: number;

  @Column()
  imei: string;

  @Column('datetime')
  dateTime: Date;

  @Column()
  localization: string;

  @Column()
  type: string;

  @Column()
  data: string;

  @Column({
    type: 'datetime',
    nullable: true,
  })
  synchronizedAt: Date;

  @Column({
    type: 'text',
    default: ESyncStatus.UNSYNCHRONIZED,
  })
  syncStatus: string;

  @Column({
    type: 'numeric',
    default: 0,
  })
  syncAttempts: number;

  @Column({
    type: 'numeric',
    nullable: true,
  })
  syncErrorCode: number;
}
