import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { Account, Credentials } from '../models';
import { Observable } from 'rxjs';

type ResponseType = HttpResponse<Account>;

@Injectable({
  providedIn: 'root'
})
export class AccountService {
  private url = 'account';

  constructor(
    private _http: HttpClient
  ) { }

  /**
   * Pobranie aktualnie zalogowanego użytkownika
   */
  getAccount(): Observable<ResponseType> {
    return this._http.get<Account>(`${this.url}`, { observe: 'response' });
  }

  /**
   * Zmiana hasła
   */
  changePassword(password: string, newPassword: string): Observable<HttpResponse<{}>> {
    return this._http.post(`${this.url}/change-password`, {password, newPassword}, {observe: 'response'});
  }

  /**
   * Aktualizacja swoich danych
   */
  updateLoggedUser(account: Account): Observable<HttpResponse<Credentials>> {
    return this._http.patch<Credentials>(`${this.url}`, account, {observe: 'response'});
  }

  updateLoggedUserContact(contactData: any): Observable<HttpResponse<Account>> {
    return this._http.patch<Account>(`${this.url}/contact`, contactData, {observe: 'response'});
  }

  /**
   * Dezaktywacja konta zalogowanego użytkownika
   */
  deactivateLoggedUser(email: string): Observable<HttpResponse<any>> {
    return this._http.delete(`${this.url}/${email}`, {observe: 'response'});
  }
}
