import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslocoModule } from '@ngneat/transloco';
import { ErrorComponent } from './components/error/error.component';
import { MomentDatePipe } from './pipes/moment-date.pipe';
import { BluetoothSerial } from '@awesome-cordova-plugins/bluetooth-serial/ngx';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ShowHidePasswordComponent } from './components/show-hide-password/show-hide-password.component';
import { IonicModule } from '@ionic/angular';

@NgModule({
    exports: [
        TranslocoModule,
        ErrorComponent,
        ShowHidePasswordComponent,
        MomentDatePipe,
    ],
  declarations: [
    ErrorComponent,
    ShowHidePasswordComponent,
    MomentDatePipe,
  ],
  imports: [
    CommonModule,
    TranslocoModule,
    ReactiveFormsModule,
    FormsModule,
    IonicModule,
  ],
  providers: [
    BluetoothSerial,
  ]
})
export class SharedModule { }
