import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivate,
  CanActivateChild,
  Router,
  RouterStateSnapshot,
  UrlTree
} from '@angular/router';
import { Observable } from 'rxjs';
import { CredentialsService } from '../services';

@Injectable({
  providedIn: 'root'
})
export class OnlyNoAuthGuard implements CanActivate, CanActivateChild {

  constructor(
    private _router: Router,
    private _credentialsService: CredentialsService
  ) {
  }

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): boolean {
    return this.check(state.url);
  }

  canActivateChild(
    childRoute: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    return this.check(state.url);
  }

  /**
   * Check the authenticated status
   *
   * @private
   */
  private check(url: string): boolean {
    if (this._credentialsService.isAuthenticated()) {
      this._router.navigate(['/']);
    }

    return true;
  }
}
