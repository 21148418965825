import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor, HttpResponse, HttpErrorResponse
} from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { AuthenticationService } from '../../auth/services';
import { Router } from '@angular/router';
import { tap } from 'rxjs/operators';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  private showMsg = false;

  constructor(
    private _authenticationService: AuthenticationService,
    private _router: Router
  ) {
  }

  intercept(
    request: HttpRequest<unknown>,
    next: HttpHandler
  ): Observable<HttpEvent<unknown>> {
    if (this._authenticationService.getToken() === null) {
      return next.handle(request);
    }

    if (this._authenticationService.getToken() != null) {
      request = this.addToken(request);
    }

    return next.handle(request).pipe(
      tap(
        (event: HttpEvent<any>) => {
          if (event instanceof HttpResponse) {
            //  Response is OK
            this.showMsg = false;
          }
        },
        (err: any) => {
          if (err instanceof HttpErrorResponse) {
            if (err.status === 401) {
              // Unauthorized, redirect to login page

              this._authenticationService.logout();
              this._router.navigate(['/auth/login'], { replaceUrl: true });
            }
          }
          return of(err);
        }
      )
    );
  }

  private addToken(request: HttpRequest<any>): any {
    return request.clone({
      setHeaders: {
        Authorization: `${this._authenticationService.getAuthHeader()}`,
      },
    });
  }
}
