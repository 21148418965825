import { Routes } from '@angular/router';

export const AUTH_ROUTES: Routes = [
  {
    path: 'account',
    loadChildren: () => import('../../pages/account/account.module').then(m => m.AccountPageModule),
  },
  {
    path: 'account/profile',
    loadChildren: () => import('../../pages/profile/profile.module').then(m => m.ProfilePageModule),
  },
  {
    path: 'account/my-subscriptions',
    loadChildren: () => import('../../pages/my-subscriptions/my-subscriptions.module').then(m => m.MySubscriptionsPageModule),
  },
  {
    path: 'account/my-device',
    loadChildren: () => import('../../pages/my-device/my-device.module').then( m => m.MyDevicePageModule)
  },
  {
    path: 'account/my-measurement-fields',
    loadChildren: () => import('../../pages/my-measurement-fields/my-measurement-fields.module').then( m => m.MyMeasurementFieldsPageModule)
  },
  {
    path: 'account/my-audit',
    loadChildren: () => import('../../pages/my-audit/my-audit.module').then( m => m.MyAuditPageModule)
  },
];
