import { Injectable } from '@angular/core';
import { Translation, TranslocoService } from '@ngneat/transloco';
import { PrimeNGConfig } from 'primeng/api';
import { PRIMENG_LOCALE } from '../../../assets/i18n/prime-ng/prime-ng.locale';
import { SettingsService } from '../../shared/services/settings.service';

@Injectable({
  providedIn: 'root'
})
export class LangCustomService {

  constructor(
    private _translocoService: TranslocoService,
    private _primeNGConfig: PrimeNGConfig,
    private _settingsService: SettingsService,
  ) { }

  /**
   * Ustawienie języka, jeśli istnieje klucz
   */
  public setActiveUserLang(langKey?: string): Promise<Translation> {
    const settings = this._settingsService.getSettings();
    if (!langKey && !this._translocoService.getAvailableLangs().some(item => item === langKey) && !PRIMENG_LOCALE[langKey]) {
      langKey = settings.langKey ?? this._translocoService.getDefaultLang();
    }

    settings.langKey = langKey;
    this._primeNGConfig.setTranslation(PRIMENG_LOCALE[langKey]);
    this._translocoService.setActiveLang(langKey);
    this._settingsService.saveSettings(settings);

    return this._translocoService.load(langKey).toPromise();
  }
}
