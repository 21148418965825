import { Injectable } from '@angular/core';
import { Translation, TranslocoService } from '@ngneat/transloco';
import { PrimeNGConfig } from 'primeng/api';
import { PRIMENG_LOCALE } from '../../../assets/i18n/prime-ng/prime-ng.locale';
import { ISettings } from '../models/settings.model';

@Injectable({
  providedIn: 'root'
})
export class SettingsService {

  private settings?: ISettings;

  constructor(
    private _translocoService: TranslocoService,
    private _primeNGConfig: PrimeNGConfig,
  ) {
    this.settings = this.getSettings();
  }

  public saveSettings(settings: ISettings): void {
    localStorage.setItem('settings', JSON.stringify(settings));
    this.settings = settings;
  }

  public getSettings(): ISettings {
    const settingsFromStorage = JSON.parse(window.localStorage.getItem('settings'));

    return {
      langKey: settingsFromStorage?.langKey ?? 'pl',
      automaticLogin: settingsFromStorage?.automaticLogin ?? false,
    };
  }
}
