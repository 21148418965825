import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor, HttpClient, HttpResponse, HttpErrorResponse
} from '@angular/common/http';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { ToastUtil } from '../../shared/util/toast-util';

@Injectable()
export class AlertInterceptor implements HttpInterceptor {

  constructor(
    private _http: HttpClient,
    private _toastUtil: ToastUtil,
  ) {
  }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(request).pipe(
      tap(
        (event: HttpEvent<any>) => this.alertHandler(event),
        (error: HttpErrorResponse) => this.errorAlertHandler(error)
      )
    );
  }

  /**
   * Alert handler
   *
   * @param response
   * @private
   */
  private alertHandler(response: HttpEvent<any>): void {
    if (response instanceof HttpResponse) {
      response.headers.keys().forEach(entry => {
        const translationKey = response.headers.get(entry) ?? '';

        if (entry.endsWith('success')) {
          this._toastUtil.presentSuccessToast(translationKey);
        } else if (entry.endsWith('warning')) {
          this._toastUtil.presentWarningToast(translationKey);
        }
      });

    }
  }

  /**
   * Error handler
   *
   * @param response
   * @private
   */
  private errorAlertHandler(response: HttpErrorResponse): void {
    let translationKey = '';

    response.headers.keys().forEach(entry => {
      if (entry.endsWith('error')) {
        translationKey = response.headers.get(entry) ?? '';
      }
    });

    if (!translationKey && response.status) {
      translationKey = `error.${response.status}`;
    }

    if (response.status !== 404 && response.status !== 403 && response.status !== 401) {
      this._toastUtil.presentErrorToast(translationKey);
    }
  }
}
