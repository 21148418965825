import { Component, OnInit } from '@angular/core';
import { AuthenticationService, CredentialsService } from '../../auth/services';
import { NavigationEnd, Router } from '@angular/router';
import { Location } from '@angular/common';
import { NavigationService } from '../../config/menu/navigation.service';
import { NavigationItem } from '../../shared/models/navigation-item.model';

@Component({
  selector: 'app-content',
  templateUrl: './content.component.html',
  styleUrls: ['./content.component.scss'],
})
export class ContentComponent implements OnInit {

  public title: string;
  public appPages: NavigationItem[];

  constructor(
    private _authenticationService: AuthenticationService,
    private _router: Router,
    private _credentialService: CredentialsService,
    private location: Location,
    private _navigationService: NavigationService
  ) {
    this.appPages = this._navigationService.allNavigations;

    this._router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        this.initTitle();
      }
    });
  }

  ngOnInit() {
  }

  isAuthenticated(): boolean {
    return this._credentialService.isAuthenticated();
  }

  logout(): void {
    this._authenticationService.logout();
    this._router.navigate(['auth/login'], {replaceUrl: true});
  }

  private initTitle() {
    let path = this.location.prepareExternalUrl(this.location.path());
    path = path.replace(/\?.*$/, '');
    this.title = this._navigationService.getPageTitle(path);
  }
}
