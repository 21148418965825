import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { CredentialsService } from './credentials.service';
import { Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';
import { NgxRolesService } from 'ngx-permissions';
import { Credentials } from '../models';
import { LangCustomService } from '../../core/transloco/lang-custom.service';
import { Router } from '@angular/router';
import { TimerService } from '../../shared/services/timer.service';

export interface LoginContext {
  email: string;
  password: string;
}

@Injectable({
  providedIn: 'root',
})
export class AuthenticationService {

  constructor(
    private _http: HttpClient,
    private _router: Router,
    private _credentialsService: CredentialsService,
    private _ngxRolesService: NgxRolesService,
    private _translocoCustomService: LangCustomService,
    private _timerService: TimerService
  ) {
  }

  /**
   * Authenticates the user.
   *
   * @param context The login parameters.
   * @return The user credentials.
   */
  login(context: LoginContext): Observable<Credentials> {
    const data = {
      email: context.email,
      password: context.password
    };

    return this._http.post(`authenticate`, data).pipe(
      map(
        (res: any) => this.onAuthSuccess(res, true),
        (err: any) => err
      )
    );
  }

  onAuthSuccess(res: any, rememberMe = false): any {
    const credentials: Credentials = {
      user: res.user,
      username: res.user.email,
      token: res.id_token,
      refreshToken: res.refresh_token
    };

    this._credentialsService.setCredentials(credentials, rememberMe);
    credentials.user.roles.forEach(role => this._ngxRolesService.addRoleWithPermissions(role.name, role.permissions));

    // ustawienie języka
    this._translocoCustomService.setActiveUserLang(credentials.user.langKey);

    return res;
  }

  /**
   * Logs out the user and clear credentials.
   *
   * @return True if the user was logged out successfully.
   */
  logout(): Observable<boolean> {
    this._credentialsService.setCredentials();
    this._ngxRolesService.flushRolesAndPermissions();
    this._timerService.clearTokenTimer();
    return of(true);
  }

  getToken(): string {
    return this._credentialsService.getToken();
  }

  getAuthHeader(): string {
    return this._credentialsService.getAuthHeader();
  }
}
