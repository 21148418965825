import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { EmptyComponent } from './empty/empty.component';
import { ContentComponent } from './content/content.component';
import { IonicModule } from '@ionic/angular';
import { RouterModule } from '@angular/router';
import { SharedModule } from '../shared/shared.module';
import { TRANSLOCO_SCOPE } from '@ngneat/transloco';


@NgModule({
  declarations: [
    EmptyComponent,
    ContentComponent
  ],
  imports: [
    CommonModule,
    IonicModule,
    RouterModule,
    SharedModule,
  ],
  providers: [
    {
      provide: TRANSLOCO_SCOPE, useValue: 'menu'
    }
  ]
})
export class LayoutModule {
}
