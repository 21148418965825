import { Column, Entity, PrimaryColumn } from 'typeorm';

@Entity('localization')
export class Localization {

  @PrimaryColumn()
  id: number;

  @Column('text')
  shortName: string;

  @Column('text')
  objectShortName: string;
}
