import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { NOT_AUTH_ROUTES } from './config/routes/not-auth.routes';
import { AUTH_ROUTES } from './config/routes/auth.routes';
import { ContentComponent } from './layout/content/content.component';
import { NoAuthGuard } from './auth/guards/no-auth.guard';
import { AuthGuard } from './auth/guards/auth.guard';
import { ONLY_NOT_AUTH_ROUTES } from './config/routes/only-not-auth.routes';
import { OnlyNoAuthGuard } from './auth/guards/only-no-auth.guard';

const routes: Routes = [
  {
    path: '',
    redirectTo: 'connections',
    pathMatch: 'full'
  },
  // Routingi dostępne tylko dla niezalogowanego użytkownika (np. logowanie)
  {
    path: 'auth',
    children: ONLY_NOT_AUTH_ROUTES,
    component: ContentComponent,
    canActivate: [OnlyNoAuthGuard],
  },
  // Routingi dostępne dla wszystkich
  {
    path: '',
    children: NOT_AUTH_ROUTES,
    component: ContentComponent,
    canActivate: [NoAuthGuard]
  },
  // Routingi dostępne tylko dla zalogowanego użytkownika
  {
    path: 'authorized',
    children: AUTH_ROUTES,
    component: ContentComponent,
    canActivate: [AuthGuard]
  },
  {
    path: '**',
    redirectTo: 'error/404'
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule {}
