import { ToastController } from '@ionic/angular';
import { TranslocoService } from '@ngneat/transloco';
import { Injectable } from '@angular/core';
import { ERROR, SUCCESS, WARNING } from '../constants/toast.constants';

@Injectable({providedIn: 'root'})
export class ToastUtil {
  constructor(
    protected _toastController: ToastController,
    private _translocoService: TranslocoService,
  ) {
  }

  presentSuccessToast(message: string = '') {
    this.presentToast(message, SUCCESS);
  }

  presentWarningToast(message: string = '') {
    this.presentToast(message, WARNING);
  }

  presentErrorToast(message: string = '') {
    this.presentToast(message, ERROR);
  }

  private async presentToast(message: string, type) {
    return this._toastController.create({
      header: this._translocoService.translate(type.header),
      message: this._translocoService.translate(message),
      duration: 5000,
      color: type.color,
      buttons: [
        {
          side: 'start',
          icon: type.icon,
        },
        {
          icon: 'close',
          role: 'cancel',
        }
      ],
    }).then(toast => toast.present());
  }
}
