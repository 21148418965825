import { Component, OnInit } from '@angular/core';
import { AccountService, AuthenticationService, CredentialsService, RefreshTokenService } from './auth/services';
import { Router } from '@angular/router';
import { NgxRolesService } from 'ngx-permissions';
import { LangCustomService } from './core/transloco/lang-custom.service';
import { SettingsService } from './shared/services/settings.service';
import { ToastUtil } from './shared/util/toast-util';
import { DatabaseService } from './shared/services/database/database.service';

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
})
export class AppComponent implements OnInit {
  public isWeb = false;

  constructor(
    private _credentialsService: CredentialsService,
    private _langCustomService: LangCustomService,
    private _accountService: AccountService,
    private _authenticationService: AuthenticationService,
    private _refreshTokenService: RefreshTokenService,
    private _settingsService: SettingsService,
    private _ngxRolesService: NgxRolesService,
    private _router: Router,
    private _toastUtil: ToastUtil,
    private _databaseService: DatabaseService,
  ) {
  }

  async ngOnInit(): Promise<void> {
    // set PrimeNg lang and Transloco
    this._langCustomService.setActiveUserLang().then();

    // refresh token
    if (this._credentialsService.isAuthenticated()) {
      this.refreshToken();
    }

    try {
      // Initialize Database connection
      await this._databaseService.initializeConnection();
    } catch (e) {
      this._toastUtil.presentErrorToast(e);
    }
  }

  /**
   * Refresh token
   */
  refreshToken(): void {
    if (this._settingsService.getSettings().automaticLogin) {
      this._refreshTokenService.refresh().subscribe();
    }
    else {
      this._authenticationService.logout();
    }
  }
}
