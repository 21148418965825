import { Injectable } from '@angular/core';
import { CredentialsService } from './credentials.service';
import { AuthenticationService } from './authentication.service';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { Credentials } from '../models';
import { NgxRolesService } from 'ngx-permissions';
import { EMPTY, Observable } from 'rxjs';
import { TimerService } from '../../shared/services/timer.service';
import jwtDecode, { JwtPayload } from 'jwt-decode';
import { Router } from '@angular/router';
import { catchError, map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class RefreshTokenService {

  constructor(
    private _credentialsService: CredentialsService,
    private _authenticationService: AuthenticationService,
    private _ngxRolesService: NgxRolesService,
    private _timerService: TimerService,
    private _http: HttpClient,
    private _router: Router
  ) {
  }

  refresh(): Observable<Credentials> {
    const refreshToken = this._credentialsService.getRefreshToken();

    if (!refreshToken) {
      return;
    }

    return this.refreshHttp(refreshToken)
      .pipe(
        catchError(() => this.onError()),
        map(
          (res: any) => this.onSuccess(res),
        )
      );
  }

  public setTokenRefreshing(): void {
    this._timerService.clearTokenTimer();
    this._timerService.refreshToken(this.getExpiration(), () => this.refresh().subscribe());
  }

  private onSuccess(res: any): Credentials {
    const credentials: Credentials = {
      user: res.user,
      username: res.user.email,
      token: res.id_token,
      refreshToken: res.refresh_token,
    };

    this._credentialsService.setCredentials(credentials, true); // save in localstorage
    credentials.user.roles.forEach(role => this._ngxRolesService.addRoleWithPermissions(role.name, role.permissions));
    this._timerService.refreshToken(this.getExpiration(), () => this.refresh().subscribe());

    return credentials;
  }

  private onError(): Observable<any> {
    this._authenticationService.logout();
    this._router.navigate(['/auth/login']);

    return EMPTY;
  }

  private getExpiration(): number {
    const token = this._credentialsService.getToken();
    const decoded: JwtPayload = jwtDecode(token);

    return new Date(decoded.exp * 1000).getTime();
  }

  private refreshHttp(refreshToken: string): Observable<HttpResponse<any>> {
    return this._http.post<any>(`authenticate/refresh`, { refreshToken });
  }
}
