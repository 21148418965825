import { Column, Entity, ManyToMany, PrimaryGeneratedColumn } from 'typeorm';
import { Parameter } from './parameter';
import { JoinTable } from 'typeorm/browser';

@Entity('probe')
export class Probe {
  @PrimaryGeneratedColumn()
  id: number;

  @Column({
    type: 'text',
    nullable: false,
  })
  name: string;

  @ManyToMany(
    () => Parameter,
    (parameter) => parameter.probes,
    {
      cascade: true,
    },
  )
  @JoinTable()
  parameters: Parameter[];
}
