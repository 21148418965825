import { Injectable } from '@angular/core';
import { NavigationItem } from '../../shared/models/navigation-item.model';

@Injectable({
  providedIn: 'root'
})
export class NavigationService {

  private navigations: NavigationItem[] = [
    { title: 'home', url: '/home', icon: 'home' },
    { title: 'connections', url: '/connections', icon: 'link' },
    { title: 'rememberedDevices', url: '/remembered-devices', icon: 'hardware-chip', children:[
        {title: 'measurementChart', url: '/measurement-chart', icon: 'analytics-outline'},
        {title: 'meshChart', url: '/measurement-mesh', icon: 'map-outline'},
        { title: 'measurementDatabase', url: '/measurements', icon: 'server' },
      ],
    },
    { title: 'myAccount', url: '/authorized/account', icon: 'id-card', children: [
        { title: 'myProfile', url: '/authorized/account/profile', icon: 'person', children: [
            { title: 'profileForm', url: '/authorized/account/profile/account-form', icon: 'pencil' },
            { title: 'changePassword', url: '/authorized/account/profile/change-password', icon: 'key' },
            { title: 'deleteAccount', url: '/authorized/account/profile/delete-account', icon: 'delete-account' },
          ],
        },
        { title: 'mySubscription', url: '/authorized/account/my-subscriptions', icon: 'card' },
        { title: 'myDevice', url: '/authorized/account/my-device', icon: 'hardware-chip' },
        { title: 'myMeasurementFiled', url: '/authorized/account/my-measurement-fields', icon: 'navigate-outline' },
        { title: 'myAudit', url: '/authorized/account/my-audit', icon: 'calendar' },
      ],
    },
    { title: 'settings', url: '/settings', icon: 'settings' },
    { title: 'about', url: '/about', icon: 'information-circle' },
  ];

  constructor() {
  }

  get allNavigations(): NavigationItem[] {
    return this.navigations;
  }

  get accountPageNavigations(): NavigationItem[] {
    return this.navigations.find(item => item.title === 'myAccount').children;
  }

  public getPageTitle(path: string): string {
    const title = this.findTitle(this.navigations, path);

    return title ? `menu.title.${title}` : '';
  }

  private findTitle(navigations: NavigationItem[], path: string): string {
    for (const nav of navigations) {
      if (nav.url === path) {
        return nav.title;
      }

      if (nav.children?.length) {
        const result = this.findTitle(nav.children, path);
        if (result) {
          return result;
        }
      }
    }

    return '';
  }
}
