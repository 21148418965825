import { Column, Entity, ManyToMany, PrimaryGeneratedColumn } from 'typeorm';
import { Probe } from './probe';

@Entity('parameter')
export class Parameter {
  @PrimaryGeneratedColumn()
  id: number;

  @Column({
    type: 'text',
    nullable: false,
  })
  name: string;

  @ManyToMany(
    () => Probe,
    (probe) => probe.parameters,
  )
  probes: Probe[];
}
