export const PRIMENG_LOCALE = {
    pl: {
        startsWith: 'Zacznij od',
        contains: 'Zawiera',
        notContains: 'Nie zawiera',
        endsWith: 'Kończy na',
        equals: 'Równe',
        notEquals: 'Różne od',
        noFilter: 'Brak filtra',
        lt: 'Mniej niż',
        lte: 'Mniejsze niż lub równe',
        gt: 'Większe niż',
        gte: 'Większe niż lub równe',
        is: 'Jest',
        isNot: 'Nie jest',
        before: 'Przed',
        after: 'Po',
        clear: 'Wyczyść',
        apply: 'Zatwierdź',
        matchAll: 'Dopasuj wszystko',
        matchAny: 'Dopasuj dowolne',
        addRule: 'Dodaj regułę',
        removeRule: 'Usuń regułę',
        accept: 'Tak',
        reject: 'Nie',
        choose: 'Wybierz',
        upload: 'Wyślij',
        cancel: 'Anuluj',
        dayNames: ['Niedziela', 'Poniedziałek', 'Wtorek', 'Środa', 'Czwartek', 'Piątek', 'Sobota'],
        dayNamesShort: ['Niedz', 'Pon', 'Wt', 'Śr', 'Czw', 'Pią', 'Sob'],
        dayNamesMin: ['Nd', 'Pn', 'Wt', 'Śr', 'Czw', 'Pt', 'Sb'],
        monthNames: [ 'Styczeń', 'Luty', 'Marzec', 'Kwiecień', 'Maj', 'Czerwiec', 'Lipiec', 'Sierpień', 'Wrzesień', 'Październik', 'Listopad', 'Grudzień' ],
        monthNamesShort: [ 'Sty', 'Lut', 'Mar', 'Kwi', 'Maj', 'Cze', 'Lip', 'Sie', 'Wrz', 'Paź', 'Lis', 'Gru' ],
        today: 'Dzisiaj',
        weekHeader: 'Tydz',
        weak: 'Słabe',
        medium: 'Średnie',
        strong: 'Silne',
        passwordPrompt: 'Wprowadź hasło',
        emptyMessage: 'Brak wyników',
        emptyFilterMessage: 'Nie znaleziono wyników'
    },
    en: {
        startsWith: 'Starts with',
        contains: 'Contains',
        notContains: 'Not contains',
        endsWith: 'Ends with',
        equals: 'Equals',
        notEquals: 'Not equals',
        noFilter: 'No Filter',
        lt: 'Less than',
        lte: 'Less than or equal to',
        gt: 'Greater than',
        gte: 'Great then or equals',
        is: 'Is',
        isNot: 'Is not',
        before: 'Before',
        after: 'After',
        clear: 'Clear',
        apply: 'Apply',
        matchAll: 'Match All',
        matchAny: 'Match Any',
        addRule: 'Add Rule',
        removeRule: 'Remove Rule',
        accept: 'Yes',
        reject: 'No',
        choose: 'Choose',
        upload: 'Upload',
        cancel: 'Cancel',
        dayNames: ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'],
        dayNamesShort: ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'],
        dayNamesMin: ['Su', 'Mo', 'Tu', 'We', 'Th', 'Fr', 'Sa'],
        monthNames: ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'],
        monthNamesShort: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'],
        today: 'Today',
        weekHeader: 'Wk',
        weak: 'Weak',
        medium: 'Medium',
        strong: 'Strong',
        passwordPrompt: 'Enter a password',
        emptyMessage: 'No results found',
        emptyFilterMessage: 'No results found'
    }
};
