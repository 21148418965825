import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class TimerService {

  private refreshTokenTimer;

  constructor(
  ) { }

  refreshToken(expiration: number, refreshFunc: () => any): void {
    const timeout = expiration - Date.now() - (30 * 1000);
    this.refreshTokenTimer = setTimeout(() => refreshFunc(), timeout);
  }

  clearTokenTimer(): void {
    clearTimeout(this.refreshTokenTimer);
  }
}
