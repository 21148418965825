import { Column, Entity, PrimaryGeneratedColumn } from 'typeorm';

@Entity('device')
export class Device {
  @PrimaryGeneratedColumn()
  id: number;

  @Column({
    type: 'text',
    nullable: true,
  })
  localName: string;

  @Column('text')
  imei: string;

  @Column({
    type: 'text',
    nullable: true,
  })
  login: string;

  @Column({
    type: 'text',
    nullable: true,
  })
  password: string;

  @Column({
    type: 'text',
    unique: true,
  })
  mac: string;
}
