import { Column, Entity, PrimaryGeneratedColumn } from 'typeorm';

// TODO tylko w ramach testów
@Entity('example')
export class Example {
  @PrimaryGeneratedColumn()
  id: number;

  @Column({
    type: 'text',
  })
  name: string;
}
