import { Pipe, PipeTransform } from '@angular/core';
import { Moment } from 'moment';

@Pipe({
  name: 'momentDate'
})
export class MomentDatePipe implements PipeTransform {

  transform(value: Moment | string): Date | string {
    if (typeof value === 'object') {
      return value.toDate();
    }
    return value;
  }

}
